import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Avatar from "@material-ui/core/Avatar"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Dialog from "@material-ui/core/Dialog"
import AddIcon from "@material-ui/icons/Add"
import { blue } from "@material-ui/core/colors"
import {
  EmailShareButton,
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"

import {
  EmailIcon,
  FacebookIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share"

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  sharebutton: {
    width: "100%",
  },
})

export interface ShareDialogProps {
  open: boolean
  selectedTour: string
  onClose: () => void
}

export function ShareDialog(props: ShareDialogProps) {
  const classes = useStyles()
  const { onClose, selectedTour, open } = props
  const shareURL = "www.witholesia.com/" + selectedTour + "/"
  // console.log({shareURL})

  const handleClose = () => {
    onClose()
  }

  const handleListItemClick = (value: string) => {
    onClose()
  }

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle id="simple-dialog-title">Share with...</DialogTitle>
      <List>
        <EmailShareButton
          className={classes.sharebutton}
          url={shareURL}
        >
          <ListItem button>
            <ListItemAvatar>
              <Avatar>
                <EmailIcon size={48} round={true} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="E-Mail" />
          </ListItem>
        </EmailShareButton>

        <FacebookShareButton
          className={classes.sharebutton}
          url={shareURL}
        >
          <ListItem button>
            <ListItemAvatar>
              <Avatar>
                <FacebookIcon size={48} round={true} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Facebook" />
          </ListItem>
        </FacebookShareButton>

        <WhatsappShareButton
          className={classes.sharebutton}
          url={shareURL}
        >
          <ListItem button>
            <ListItemAvatar>
              <Avatar>
                <WhatsappIcon size={48} round={true} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="WhatsApp" />
          </ListItem>
        </WhatsappShareButton>

        <TelegramShareButton
          className={classes.sharebutton}
          url={shareURL}
        >
          <ListItem button>
            <ListItemAvatar>
              <Avatar>
                <TelegramIcon size={48} round={true} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Telegram" />
          </ListItem>
        </TelegramShareButton>

        <RedditShareButton
          className={classes.sharebutton}
          url={shareURL}
        >
          <ListItem button>
            <ListItemAvatar>
              <Avatar>
                <RedditIcon size={48} round={true} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Reddit" />
          </ListItem>
        </RedditShareButton>
      </List>
    </Dialog>
  )
}
