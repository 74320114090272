import React from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import { IconButton } from "@material-ui/core"
import Link from "@material-ui/core/Link"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Button from "@material-ui/core/Button"
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined"
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined"

import { ShareDialog } from "../share-dialog/share-dialog"
import SvgComingSoon from "../svg-comps/coming-soon"

import GridListTileBar from "@material-ui/core/GridListTileBar"

import Img, { FluidObject } from "gatsby-image"

export interface TourCardProps {
  thumbnail: FluidObject
  title: string
  description: string
  slug: string
  imgoffset: number
  datelist: Array<{
    selling: boolean
  }>
}

const useStyles = makeStyles<Theme, { imgoffset }>(theme =>
  createStyles({
    card: {
      maxWidth: 280,
      height: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      position: "relative",
      "&:hover": {
        transform: "scale(1.01)",
      },
      "& a:hover": {
        textDecoration: "none",
      },
    },
    media: {
      height: 280,
      overflow: "hidden",
      zIndex: 0,
      position: "relative",
    },
    image: {
      top: ({ imgoffset }) => `calc(-${imgoffset}%)`,
    },
    linkwrap: {
      "&:hover": {
        textDecoraton: "none",
      },
    },
    table: {
      paddingBottom: 0,
      paddingTop: 0,
      margin: 0,
      border: "none",
    },
    sidebutton: {
      float: "right",
      fontFamily: "inherit",
      textTransform: "none",
      fontWeight: "bold",
    },
    titleBar: {
      background:
        "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
        "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
      fontFamily: "inherit",
      zIndex: 1,
    },
    actions: {
      position: "absolute",
      bottom: 0,
      height: "60px",
      marginBottom: 0,
      width: "100%",
    },
    description: {
      marginBottom: "40px",
      height: "100%",
    },
    cardbutton: {
      height: "100%",
    },
    comingsoon: {
      position: "absolute",
      left: 0,
      top: 0,
      paddingRight: "50%",
      paddingTop: "50%",
      paddingBottom: "1rem",
      paddingLeft: "1rem",
      height: "100%",
      width: "100%",
      zIndex: 999,
    },
  })
)

export const TourCard: React.FC<TourCardProps> = ({
  thumbnail,
  title,
  description,
  slug,
  imgoffset,
  datelist,
}) => {
  const classes = useStyles({ imgoffset })
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  var isComingSoon = true

  datelist.forEach(date => {
    if (date.selling) {
      isComingSoon = false
    }
  })

  return (
    <Card className={classes.card}>
      <Link
        className={classes.linkwrap}
        href={"/" + slug + "/"}
        color="inherit"
      >
        <CardActionArea aria-label="tour" className={classes.cardbutton}>
          <CardMedia className={classes.media}>
            {isComingSoon ? (
              <SvgComingSoon className={classes.comingsoon} />
            ) : null}
            <Img fluid={thumbnail} alt={title} className={classes.image} />
          </CardMedia>
          <GridListTileBar
            title={title}
            titlePosition="top"
            className={classes.titleBar}
          />
          <CardContent className={classes.description}>
            {/* <h2>{title}</h2> */}
            {description}
          </CardContent>
        </CardActionArea>
      </Link>

      <CardActions className={classes.actions}>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td className={classes.table}>
                {/* <IconButton size="medium" aria-label="shop" color="inherit">
                  <ShoppingCartOutlinedIcon />
                </IconButton> */}
                <IconButton
                  color="inherit"
                  size="medium"
                  onClick={handleClickOpen}
                  aria-label="share"
                >
                  <ShareOutlinedIcon />
                </IconButton>
                <ShareDialog
                  selectedTour={slug}
                  open={open}
                  onClose={handleClose}
                />
              </td>
              <td className={classes.table}>
                <Link
                  className={classes.linkwrap}
                  href={"/" + slug + "/"}
                  color="inherit"
                >
                  <Button
                    className={classes.sidebutton}
                    size="large"
                    aria-label="more"
                  >
                    Більше
                  </Button>
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </CardActions>
    </Card>
  )
}
