import React from "react"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

import { TourCard } from "../tourcard/tourcard"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: "inherit",
      padding: "0.1rem",
    },
    card: {
      minWidth: 250,
      margin: "1%",
    },
  })
)

export interface TourGalleryProps {
  children: any
}

export const TourGallery: React.FC<TourGalleryProps> = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={1} alignContent='center' justify='center'>
        {children.map(({ node }, index) => (
          <Grid key={index} item xs className={classes.card}>
            <TourCard
              thumbnail={node.thumbnail.childImageSharp.fluid}
              title={node.title}
              description={node.summary}
              slug={node.slug}
              imgoffset={!!(node.thumbnailoffset)?node.thumbnailoffset:8}
              datelist={node.datelist}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
