import * as React from "react";

function SvgComingSoon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 337.04 297.79"
      width="1em"
      height="1em"
      {...props}
    >
      <defs>
        <style>
          {
            ".coming_soon_svg__cls-2{fill:#fff;stroke-width:6px;stroke:#000;stroke-linejoin:round}"
          }
        </style>
      </defs>
      <g id="coming_soon_svg__Livello_2" data-name="Livello 2">
        <g id="coming_soon_svg__Livello_1-2" data-name="Livello 1">
          <rect
            x={147.04}
            y={2.89}
            width={13}
            height={292}
            rx={6.5}
            ry={6.5}
            transform="rotate(-2.23 153.612 148.964)"
            strokeMiterlimit={10}
            stroke="#000"
            fill="#966F33"
            strokeWidth={6}
          />
          <path
            className="coming_soon_svg__cls-2"
            d="M99.54 39.39l1.64 59 202.52-14 30.34-36-41.82-32zM226.22 112.88l2.22 61-198 2.3L3 149.09l33.38-33.88z"
          />
          <text
            transform="rotate(-5.85 843.74 -1311.03)"
            letterSpacing="-.04em"
            fontSize={36}
            strokeWidth={2}
            fontFamily="Comfortaa-Regular,Comfortaa"
            strokeMiterlimit={10}
            stroke="#000"
          >
            {"C"}
            <tspan x={26.32} y={0} letterSpacing={0}>
              {"oming"}
            </tspan>
          </text>
          <text
            transform="translate(78.59 156.57)"
            fontSize={36}
            strokeWidth={2}
            fontFamily="Comfortaa-Regular,Comfortaa"
            strokeMiterlimit={10}
            stroke="#000"
          >
            {"Soon"}
          </text>
        </g>
      </g>
    </svg>
  );
}

export default SvgComingSoon;

